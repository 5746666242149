import React, { lazy, Suspense, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';
// import ProjectsNavbar from './ProjectsNavbar';
const TanaliPageContent = lazy(()=>import('./TanaliPageContent'));
const ProjectsNavbar = lazy(()=>import('./ProjectsNavbar'));
const Footer = lazy(()=>import('./Footer'));
const SubFooter = lazy(()=>import("./SubFooter"));
const Enquiry = lazy(()=>import("./Enquiry"));

export default function TanaliPage() {
    useEffect(()=>{
        window.scroll(0,0);
      },[]);
      return (
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
            <Suspense fallback={<LoadingScreen />}>
              <ProjectsNavbar buttonOptions={[
                {label:"know us" , hrefLink:"#knowus"},
                {label:"Features" , hrefLink:"#features"},
                {label:"SPECIAL" , hrefLink:"#special"},
                {label:"Why US" , hrefLink:"#whyus"},
                {label:"Projects" , hrefLink:"/projects"},

              ]} />
                <TanaliPageContent />
                <SubFooter />
                <Footer />
                <Enquiry originOfEnquiry = "TANALI - CHIPLUN" />
            </Suspense>
        </div>
  )
}
