import React, { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
const ArticlesNavbar = lazy(() => import("./ArticlesNavbar"));
const Footer = lazy(() => import("./Footer"));
const SubFooter = lazy(()=>import("./SubFooter"));

export default function Article() {
  const [showContent , setShowContent] = useState(false);
  
  const articlesData = [
    {
        id:1,
      image:
        "https://www.incubeindia.com/incubelandmarks/images/managed-farmlands-blog.webp",
        image2:"",
        image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading:
        "Cultivating Prosperity: The Comprehensive Guide to Managed Farmland Investments in India",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`In the vast landscape of investment opportunities in India, there's a hidden gem that promises both stability and growth: Managed Farmlands. This innovative investment avenue not only ensures financial appreciation but also comes with a multitude of benefits that make it stand out amidst traditional investment options like gold, fixed deposits, and government securities. In this blog, we'll explore what managed farmlands are, their appreciable advantages, and why they might just be your golden ticket to financial prosperity.`
        },
        {
          title:`Understanding Managed Farmlands`,
          text:`Managed farmlands, in essence, are agricultural properties that are overseen and operated by professional farm management companies. These expert entities handle the day-to-day farming activities, from cultivation and harvesting to marketing the produce. Investors purchase the farmland and entrust the intricate farming processes to these specialists, thereby ensuring optimal productivity without the hassle of direct involvement.`,
        },
        {
          title:`The Appreciation Advantage`,
          text:`Steady Appreciation: Managed farmlands offer investors the potential for steady, long-term appreciation. As India's population grows and urbanization continues, the demand for agricultural products rises, making farmland a valuable and appreciating asset.`,
        },
        {
          title:`Consistent Income`,
          text:`Unlike other investments, managed farmlands provide investors with a consistent income stream through the sale of crops. This income stability acts as a cushion against market volatility.`,
        }
      ]},
      {
        contentTitle:`Why Managed Farmlands Trump Regular Agricultural Land Investments`,
        content:[
        {
          title:`Professional Expertise`,
          text:`With managed farmlands, your investment benefits from the expertise of agricultural professionals. These experts implement advanced farming techniques, ensuring maximum yield and profitability.`
        },
        {
          title:`Mitigated Risk`,
          text:`Unlike investing in raw agricultural land, managed farmlands come with reduced risk. Professional management ensures the property is utilized optimally, minimizing the risks associated with mismanagement or unpredictable market forces.`,
        },
        {
          title:`Diversification`,
          text:`Diversifying your investment portfolio is a key strategy for managing risk. Managed farmlands provide a unique opportunity to diversify into the agricultural sector, adding stability to your investment mix.`,
        },
        {
          title:`Tax Benefits of Managed Farmlands in India`,
          text:`Depreciation Deductions: Investors can claim depreciation deductions on structures and improvements on the farmland, leading to significant tax savings.`,
        },
        {
          title:`Property Tax Benefits`,
          text:`Property taxes paid on managed farmlands are deductible, reducing the overall tax liability of investors.`
        },
        {
          title:`Agricultural Tax Credits`,
          text:`Depending on the state, investors may enjoy agricultural tax credits, further enhancing the attractiveness of managed farmland investments.`
        }
      ]},
      {
        contentTitle:`Managed Farmlands vs. Traditional Investments`,
        content:[
        {
          title:`Better Than Gold`,
          text:`While gold can be volatile, managed farmlands offer a tangible, appreciating asset with the potential for consistent income. It's a real asset with intrinsic value.`
        },
        {
          title:`Outperforming Fixed Deposits`,
          text:`Managed farmlands typically provide higher returns compared to fixed deposits, especially in the long run. The steady income and appreciation make them a more lucrative choice.`,
        },
        {
          title:`Surpassing Government Securities`,
          text:`Managed farmlands offer a unique combination of income and capital appreciation, outshining the relatively low returns of government securities.

In the ever-changing landscape of investments, managed farmlands emerge as a stable, appreciating, and tax-efficient choice for the discerning investor. Their potential for consistent income, long-term appreciation, and tax benefits make them an attractive option, surpassing traditional investments like gold, fixed deposits, and government securities. As always, it's essential to conduct thorough research and consult with financial experts to make informed investment decisions and embark on a prosperous financial journey.`,
        }
      ]}
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "managed-farmland-investments",

    },
    {
        id:2,
      image:
        "https://www.incubeindia.com/incubelandmarks/images/the-tradition-of-trust.webp",
        image2:"",
        image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "The tradition of trust",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:`Value of a rupee`,
          text:`No matter how big your company, every single rupee is hard-earned and precious. Whether it is a deal of a few lakhs or several crores, all of it is valuable. The value of money is truly tested whenever you make a real estate decision to buy a property. The financial and emotional value of real estate is high. After all, it is, your property. Making this deal while getting the optimum price can be an immense blessing. You need to hire someone who can help you save. Furthermore, optimum pricing alone does not endear you to your property. The people who help you get it must be professional, with a reliable knowledge of the market. Closing a deal with savings, reliability and trust has been the experience of our esteemed client Mr Sachin Maheshwari, a Manager.`
        },
        {
          title:`One incident is information, multiple incidents form experience`,
          text:`On occasion, you might find yourself in the seller’s seat. Developing and selling of land can be a tedious process. You need the start of the entire process to be smooth and transparent. Furthermore, there needs to be a good study of your land/property to get it the maximum possible value. An estimation of the profits, expenses and other details must be with you on time. Once these things are assured, you are hesitation-free; you can earn your profits as a happy, relaxed person. Our valuable client from Mumbai, Mr Manoj Patnaik told us this as we worked with him to develop and sell his property.`,
        },
        {
          title:`In great company`,
          text:`Transparency & trust do not become recognised as traditions if they are one-hit wonders. Creating consistency is key. We work hard to make such experiences the norm rather than the exception. Having 800 other clients to whom we could deliver similarly, we hope shows our commitment to the philosophy of establishing a tradition of trust .`,
        }]
      },
      {
        contentTitle:`It is, What you Call It`,
        content:[
        {
          title:`It’s in the history`,
          text:`There is an age-old saying, “what goes up must come down”. A bullish market is bound to become bearish and vice versa. These ideas tried and tested through the centuries, are the easiest reason to invest in a market when it is down. Political and economic leaders promote a market recovery, and this is an immense opportunity to grow. Some call it “recession”. We call it, opportunity.`
        },
        {
          title:`Have faith, in land`,
          text:`Land is a commodity that will always be needed. Land, being limited, guarantees the demand. No other kind of investment has such great reliability. The promise that land offers is, thus, immense. This reliability means that as an investor, it is easier to have faith in the eventual recovery of the real estate market, even when there is a market slowdown.`,
        },
        {
          title:`More in hand`,
          text:`Taking out a loan when the market is down does not seem like the obvious thing to do. On the contrary, however, it is the best time to get a loan. It is during a crash that the RBI makes it easier & cheaper to get a loan. This presents a great chance for a real estate investor. Grabbing this chance can mean immense future profits for you.`,
        },
        {
          title:`What if, you could see it coming?`,
          text:`Experts are developing more ways to understand where the market will be. From a span of a few days to a span of a few years, they are able to estimate it all. Seeing where the market is leading can also inform you about investing early or later. This reliability of land is itself an asset. So, have you started dreaming about the piece of land you always wanted to own? Don’t dream it, we will help you own it. Do drop by for a coffee sometime next week.`,
        }
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "the-tradition-of-trust",
    },
    {
        id:3,
      image: "https://www.incubeindia.com/incubelandmarks/images/group-farming-part-I.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Group farming Part-I",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`India is a big family. It has been a family of different cultures, different languages, different cuisines. All such people, one big family.

The Indian family eats Idlis and Puran polis with the same love as Khakras and butter chicken. All these foods make for some of the tastiest dishes in the world. The khau-galli of every city has unique tastes. But this family of different khau-gallis has one thing in-common – the sumptuous food they have to offer!`
        },
        {
          title:``,
          text:`The Indian family has a fertile pan, waiting to cook amazing foods. A fertile heart, waiting to love a billion people. A fertile mind, waiting to explore all cultures.

A major part of our cultures has been to be a healthy family. In today’s fast food age, ‘Organic’ has become a movement! To give children the purest milk, the cleanest vegetables, the freshest fruits! The best way to do this is by doing what India did – different people came together to create something great! This family of Indians has turned to Group farming.`,
        },
        {
          title:``,
          text:`They come together not because they are related by blood, but because they share the same purpose. Group farms are schemes involving multiple individuals who share resources for the purpose of farming. They share the cost and use of machines so that instead of 4 people spending on 4 machines, they spend on just one!

Group farming builds the community, makes them stronger and allows them to do things much better. They get discounts because they buy together. They get higher income because they sell together! They form one big family, to get the freshest food, together, like India.`,
        },
        {
          title:``,
          text:`Group farming has sizable returns, for sure. To have amazing returns you obviously need smart investments. Smart investments in the right kind of ploughing equipment, irrigation and fertilizer. But the SMARTEST investment you would do is the land! Buying the best land is the main way to make Group Farming a success.

You must look for the land, out of so many schemes that are available. Every piece of land will have different soil quality, climate and the challenges that come along with it.`,
        },
        {
          title:``,
          text:`It’s like building a great country. You need to look at the past, make decisions for the present and prepare for the future! It’s not easy. There can be tough times, but such times can be made easier with correct experts. Experts, more than anyone, give us a good sense of direction. They can help us find the best way to success.

So why not get some direction from an expert and then step on the soil you want to make yours?`,
        }      
      ]
      }
    ],
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "farming-made-easy",
    },
    {
        id:4,
      image: "https://www.incubeindia.com/incubelandmarks/images/group-farming-part-II.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Group Farming Part-II",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`There are things that must obviously be done in India, then there are things that you don’t realise should be done in India. It’s obvious that you should visit the Taj Mahal at least once. It’s not obvious that you could also fulfil many of your own needs by making some smart investments.

As much as we might say, food, shelter and clothing are only 3/4th of the necessities. There is one other necessity that we don’t talk about much. That is money. Food, shelter, clothing and money are the necessities for survival now. So, anything that got you EVEN ONE of these things would be incredibly valuable! What would be the value of something that got TWO of these things?`
        },
        {
          title:``,
          text:`You spend your entire lives looking for something that will fulfil these four needs. You get degrees, you attend investment seminars, you read books… After doing all these things you realise one big thing – innovation pays best. You can get a job, but unless you are innovative, you won’t get a raise/promotion. You can invest your money somewhere, but unless you are innovative, you will only get nominal returns.

The people in Kerala and Telangana got on this train earlier. Maharashtra is only now waking up to this idea of innovative investment. So, what kind of innovative investment can you do?`,
        },
        {
          title:``,
          text:`The biggest example of this is Group Farming. People around the state are turning their lands into group farms and getting money and food at the same time! Group farming is a novel idea that is the perfect example of strength in unity. United, you buy farming equipment. Instead of each person spending on getting a tractor, the group buys one and the tractor services the entire group farm. Along with a distribution of the cost, this reduces the amount each person has to pay for workers and increases the returns.

Group Farming has been making waves around the world now. Awareness that you have the power to make these smart investments is growing. Being a part of this movement is easier than ever now. Incube Landmarks has been in this field for 10 years now. As India turns to Group farming, you should turn to Incube Landmarks`,
        }      
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "growing-your-wealth-farm",
    },
    {
        id:5,
      image: "https://www.incubeindia.com/incubelandmarks/images/kokan.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Why invest in Konkan",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`Konkan, a coastline of 720 kilometres on the western coast, is one of the vehement strips of India flanked by the towering Sahyadris on the east and the Arabian Sea on the west. The region beholds a broad and diverse history and has been mentioned in various ancient texts including the Mahabharata.

Konkan’s culture is silver lined by a legion of great poets, writers, sculptors and artists who call it their home, and who have secured name and fame across the world.`
        },
        {
          title:``,
          text:`The biodiversity of this region is truly mesmerising and allures a galore of tourists from all across the globe throughout the year. Having such a long coastline gives a natural impetus to water sports, which spring up on every other beach of Konkan. It is just to say, that the region also houses some of the most tranquil beaches in India.

The Konkan railway, an epoch-making project got the region its early days of fame. Since then, it has been a profitable centre for business with regional companies taking an international shape due to the coastline facilitating trade with the world. Coconut and betel nut plantations are as common as beaches in this region. In the past three decades, the government, as well as the private sector, have taken a serious interest in Konkan. Developments in the fields of tourism, trade and agriculture have given a momentum to the creation of jobs and wealth along this magnificent coastline.`,
        },
        {
          title:``,
          text:`Recent developments show that multinational companies are considering Konkan as an investment destination and are up for setting up their units around the region.

Imagine your own space in this bountiful coastline of Konkan. A sea facing bungalow, a sprawling plantation of your own, or even your own industrial set up with year-round access to international markets supported by the maritime strength of Konkan. All this with instant connectivity with the newly coming up Konkan corridor and coastal highway.`,
        },
        {
          title:``,
          text:`For an investor, it is indeed the perfect time to invest in Konkan, guaranteeing amazing results in the years to come. Today, Konkan is much more than a tourist destination. It has massive industrial complexes, offices and international chains of hotels and other business at every nook and corner.

We at Incube Landmarks, have been working in this area for more than a decade. With our expert knowledge and commitment to making our clients invest better, we wish to give them the exclusive benefit of making a profitable investment in Konkan with the highest appreciation in Maharashtra.`,
        }    
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "why-invest-in-kokan",
    },
    {
        id:6,
      image:
        "https://www.incubeindia.com/incubelandmarks/images/Investment-trends-in-the-21st-century.webp",
        image2:"",
        image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Investment trends in the 21st century",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`An investment expert was asked in an interview, “When is the perfect time to invest?” His reply was worth remembering. Replying candidly to the question, he said, “Yesterday!”

The vital message to draw from this quote that investment is necessarily a test of time & thus, patience. Any investment, be it as simple as a fixed deposit in a bank requires patience on the investor’s end. It is true that money attracts money, but it is important to understand that this process takes its own time. In today’s world, due to increased awareness about savings and investments, a lot of young investors have come into the fray.`
        },
        {
          title:``,
          text:`This article is for these young investors, who are probably thinking of making their first ever investment. When it comes to saving your money, and making it grow, the market provides you with varied options, which can often lead to confusion in the decision making process. Before you make your first investment, here are a few investment trends of the 21st Century to look out for:`,
        },
        {
          title:`The banks are changing`,
          text:`Till the early 90s, banks were the easiest and most accessible institutions for common citizens to invest into. The banks at that time did not offer much options to their small and medium scale investors. The lay man always invested in fixed deposits and bonds, which would mature over a period of 1-2 years or more through which you could earn a fixed interest, ascertained at the time of investment. Today’s banks are different. Many nationalized banks are today well connected to different financial institutions in the country and abroad, and can thus offer you much better and diverse options to invest your money in. A few private banks also have their own capital funds, allowing customers to take benefit of these through schemes like SIPs in Mutual Funds. This change in the banking sector is certainly welcome and it is something that you should take into account, while speaking to your bank about potential investments.`,
        },
        {
          title:`Increased risk appetite`,
          text:`In the last two decades, it has been noted by many experts in the field of finance, that the risk appetite of the young investor has considerably increased. What this means is that the younger lot is more open to taking certain risks with their investments. It is often said, “Higher the risk, higher the growth”. This factor must not be misunderstood. Taking a risk does not mean losing your money. Increased risk appetite is understood as the ability to make investments in opportunities with greater stakes. The calculated, and conservative investor model is slowly eroding day by day. This is one of the main reason why young investors are also looking at adding real estate or land in their investment portfolio at an earlier stage.`,
        },
        {
          title:`Holistic financial planning`,
          text:`The addition of holistic financial planning is one of the key changes that has taken place in the last two decades in the investment sector. Each investor, especially the young ones are thinking of holistic financial planning, when it comes to their portfolio. Short and long term targets are set well in advance and investment are made to achieve each target or goal at different phases of life. A short term goal can be probably taking an international vacation or renovating the office. A long term goal can be creating a second home, or opening a new manufacturing set up. A sound financial advisor plays a crucial role to understand your short term and long term goals are assists you to invest accordingly. This holistic way of financial planning is much more organized, goal oriented and cohesive. These are few of the many investment trends of the 21st Century. To understand these and other trends and practices related to investment, follow our blog. We regularly write about investments and try to educate our audience with the latest knowledge and opportunities.`,
        }    
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "investment-trends-in-21st-century",
    },
    {
        id:7,
      image: "https://www.incubeindia.com/incubelandmarks/images/Real-estate.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Real headaches in Real estate",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:`Are they debt free?`,
          text:`Liens are the right to keep ownership of someone’s property until they fulfil the debt obligation they owe. You must check if the property is collateral in any kind of a debt agreement. If you buy a property that is held as an encumbrance by a creditor. Such situations create a major hassle for you as the buyer.`
        },
        {
          title:`We don’t like surprises. Do you?`,
          text:`Surprises are not something you want when investing in real estate. Research is your friend when it comes to buying land. The key is to understand what information to research. In general, it’s best to do your bit of honest research. If not, you should at least have someone whom you can trust, fully. Having concrete knowledge is important.`,
        },
        {
          title:`Pothole-free drive`,
          text:`You need to consider the layout of the land and any potential hazards that could exist in the future. Check if your land is in the floodplain. A low-elevation property can become when it rains and storms. In a flood-prone region, securing flood insurance would be of utmost importance. As a land buyer, you should assess the land for all seasons and all scenarios. When adverse weather approaches and brings rain, you could experience mudslides or land erosion.

Not understanding the properties of the land you are buying can damage the structural integrity of your investment and even destroy the literal walls.`,
        },
        {
          title:`Ab zone search hoga aasaan`,
          text:`There are instances when land is being sold and there is existing title insurance, but the title search does not cover zoning issues. This lot may have a zoning change that did not show on searches and you will just discover later on that this lot could not be built on. To prevent this problem, always ensure you check the zoning of the land before buying it.`,
        },
        {
          title:`Sleep-well`,
          text:`Making such a considerable investment requires total alertness. If you try to do it all alone, the confusion, stress and pressure will get to you. Hire a real estate agent so that you know someone trustworthy is by your side. Make sure the real estate agent you’re working with has experience buying and selling land as well as negotiating land deals. Things they can help you with are zoning and fair market value.

Buying land in Maharashtra can seem like a daunting task. You might get headaches, get shocking surprises, suffer losses, or even lose sleep doing it. However, it can be a lot less daunting if you have the right people by your side – people who know Konkan, specifically, and Maharashtra, in general, and believe in a tradition of trust.`,
        }    
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "real-headaches-in-real-estate",
    },
    {
        id:8,
      image: "https://www.incubeindia.com/incubelandmarks/images/second-home.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "DOs and DON’Ts while investing in a second home",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`The idea of a vacation has changed over the past few decades. A cosy hotel, a room service kitchen, a gaudy lobby and vivid options to relax and enjoy. This was the predominant concept of an ideal vacation for many. For some, this seemed like a dream come true. In the last few decades, especially in since the overturn of the millennium, going for a vacation or a trip with the family has transformed as a concept. This transformation the expectation setting of people changed to a considerable extent.`
        },
        {
          title:``,
          text:`With this welcome change started the trend of having your own space to spend quality time with family and friends, and take short but fulfilling trips to this abode, fondly called the “second home”. Buying a second home in today’s ever-growing market can be indeed a rewarding experience`,
        },
        {
          title:``,
          text:`Broadly, a second home is bought for three primary reasons:`,
          keyPoints:[
            "To enjoy additional rental income.",
            "A futuristic investment looking for appreciation over a long period of time.",
            "Or simply for leisure and self-use."
          ]
        },
        {
          title:`Where my second home should be?`,
          text:`The location of the second home is crucial, when it comes to making a decision. Prices and further appreciation are dependent on the location of the second home. Here’s what the experts in land acquisition and development have to say:`,
          keyPoints:[
            "It is always preferred that the location of the property should be in the radius of 280 kms from your home town. The ideal travel time by your own car should take less than six to seven hours.",
            "The location should be at least close to any of the major city or a Taluka place. It is preferred that emergency services like Hospitals, Police, etc are in a close proximity.",
            "It should be well accessible by Tar road and Public transport too.",
            "Rental demand for tourists in the surroundings should be researched well in advance if the main purpose is to rent it out."
          ]
        },
        {
          title:`What is my type of second home?`,
          text:`Remember that a second home’s primary purpose is to spend some quality time with friends and family and to take a break from the routine, in a place of your liking and your choice. When it comes to going on a vacation, what kind of place do you normally go out to? A beach, a valley or a city? Define your preferences clearly. The land experts say:`,
          keyPoints:[
            "Second homes may have options such as dam view, sea view, hill stations, etc. Make sure your choice is best suited for you and your family.",
            " Based on your health and body structure, select the best possible alternative for you.",
            "Again for some, a single sit out home in a remote area would be ideal but some people may like to stay in an organized row bungalow or a scheme developed by a Promoter. So decide well in advance if you are going for a N.A. Property, a Farm land or an Agricultural plot."
          ]
        },
        {
          title:`How do I decide my budget?`,
          text:`A second home is always a secondary priority. Make sure that your basic requirements are sorted before you think of investing in a farm land or N.A. Property. Consult a land expert before you buy. Here what land experts have to say::`,
          keyPoints:[
            "You should very calmly & carefully calculate and rather discuss with your spouse when it comes to investing in a second home.",
            "Remember, your second home is your prestige and pride. So make sure that you have backed up your first home’s EMI, your child’s future and your other necessary liabilities before investing in a weekend home.",
            "Rental income should not be the ONLY ONE factor for you to go for a second home.",
            "Always see to it that your second home adds up to your tax benefits. Set off your rental income against your interest paid on home loans and loss from house property. Ask a tax consultant beforehand."
          ]
        },
        {
          title:``,
          text:`Now that you know the basics of investing in a second home, your much awaited dream is not far away! But wait, here are a few important tips for your second home investment.`,
          keyPoints:[
            "Do a legal paper check from your advocate before you invest.",
            "Check the basic documents such as 7/12 extracts, mutation entries, search report for the last 35 years, title report, previous owner’s sale deed, etc.",
            "Do a basic research on the upcoming new Government’s scheme, tourist spots, infrastructure growth in the vicinity and overall development of the local municipal corporation.",
            "Introspect about how you can maintain your home in terms of maintenance, minor repairs, garden maintenance, cleaning, etc.",
            "Install a CCTV with a live monitoring in your second home. This will ensure the safety of your house when you are away.",
            "Typically assign a vendor on an annual contract for the maintenance of your property. This will give a major relief to you when you actually travel to your second home.",
            "Keep the phone numbers of the local people handy with you. Eg- Neighbors, Doctors, Electrician, Grocery Stores, etc.",
            "If you don’t have your own advocate, then hire a local advocate as he would be more familiar with the local authorities. Still confused? Or have some doubts? Get in touch with a land expert today!"
          ]
        }   
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "dos-and-donts-while-investing-in-a-second-home",
    },
    {
        id:9,
      image: "https://www.incubeindia.com/incubelandmarks/images/checking-docs.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading:
        "25 points to check before buying agricultural land in Maharashtra",
        data:[
          {
          contentTitle:``,
          content:[
          {
            title:``,
            text:`Agricultural lands are the land which is generally used for farming or cultivation purposes. In Maharashtra, the agriculture land can be purchased by an existing farmer only. The farmer may be anywhere from India. But if you check all the related documents well in advance than you can avoid all the future problems that usually comes in land transactions. Below is the list of most common documents that needs to be checked before buying a land in Maharashtra.`
          },
          {
            title:`7/12 Extract`,
            text:`This extract contains details such as survey number, hissa number, gat number, size of the land, full names of the owners, crops that are grown on the land, loans if any on the land, etc. It can be obtained from the Local Talathi office or can be easily downloaded from the website - https://mahabhulekh.maharashtra.gov.in/ . You will require District name, Taluka, Village name and Gat/Sr number or Owner’s Name handy while downloading it.`,
          },
          {
            title:`6/12 Extracts`,
            text:`This extract has various mutation entries about different types of rights that are transferred or created to the legal heirs or the purchasers of the land. It shows the details of how the land has been transferred and what other rights or conditions had been added or modified. Other names of a 6/12 extracts are Fer Far, Mutation extract, Hakka Patra, D Patrak.`,
          },
          {
            title:`8-A Extract`,
            text:`This extract is in the form of book which shows the details of the land revenue tax paid on the land, types of crops, names of the owners, etc. Generally it has to be read with 6/12 and 7/12 extracts.`,
          },
          {
            title:`Land Revenue Tax Receipts `,
            text:`The Tahsildar or the officer at Tehsil office gives the receipt of the tax paid on the revenue generated from the land.`
          },
          {
            title:`Village Map & Block Plan ( Gaon Nakasha )`,
            text:`This map is needed to identify the location of the land in the village. This is to tally the actual location and location on the document.`
          },
          {
            title:`Access Roads`,
            text:`There has to be a confirmation from the Revenue office. Also there has to be a record of the approach road in the Register-26 of the Panchayat office.`
          },
          {
            title:` Soil Testing`,
            text:`If the purpose of buying the land is to grow crops, then soil testing is very essential. It gives the clarity on which crops are to be grown in the land. Soil testing can be done from the Government as well as from the Private Institutions.`
          },
          {
            title:`Reservations or Acquisitions`,
            text:`Scrutiny should be done whether any part of the land has been acquired by the P.W.D, Panchayat, Irrigation, State Government or any other authorities.`
          },
          {
            title:`Agricultural Land Ceiling`,
            text:`Categories have to be confirmed of the land. A No- Objection certificate has to be obtained from the Collector if required. ( For non – agriculturists , take guidance from the local advocates ). The size of the land should be in accordance with the Land Ceiling Act of Maharashtra.`
          },
          {
            title:`Original Title Deed`,
            text:`These should be thoroughly checked by the legal advisor in order to ensure that the land is clear from all the litigations and that the title is clear.`
          },
          {
            title:`Public Notice`,
            text:` Notice regarding the purchase of the land should be given in the minimum of two local newspapers to check the title of the land with the guidance of your legal advisor.`
          },
          {
            title:`Encumbrances`,
            text:`Bank loans, Private party mortgages, Government dues or any such charges should be checked and cleared well in advance of the sale deed`
          },
          {
            title:`Litigations `,
            text:`All litigations, if any, should be carefully studied before the sale deed of the land.`
          },
          {
            title:`Demarcation, Boundaries and Survey`,
            text:`DILR and the Circle Inspectors are the authorized officials to conduct the survey of the land.`
          },
          {
            title:`Disputes relating to boundaries`,
            text:`These types of disputes needs to be settled well in advance before actually commencing the sale deed.`
          },
          {
            title:`Claims from outsiders`,
            text:`Any boja, isaar, power of attorney or any claims should be settled before the sale deed.`
          },
          {
            title:`Family disputes`,
            text:`This has to be known and settled well in advance before the sale deed. All the disputes related to the family, joint-owners, succession entries, etc. need to be solved before.`
          },
          {
            title:`Cultivators Land`,
            text:`This type of land as listed under the Kul-holding u/s 32-G is not allowed to sell by the Collector or the Revenue Tribunal. Hence all the lands of the cultivators should be strictly avoided.`
          },
          {
            title:`Aadivasi Land `,
            text:`The lands belonging to aadivasis should not be purchased at any cost.`
          },
          {
            title:`Inami Land `,
            text:`There are many conditions attached to these types of lands. It is advisable to not involve in these types of lands.`
          },
          {
            title:` Only an agriculturist`,
            text:`Only an agriculturist can acquire an agricultural land. A non-agriculturist can purchase an agriculture land with the prior consent from the District Collector.`
          },
          {
            title:`Agreement to sell `,
            text:`This should be drafted and executed from your legal advisor with all the terms and conditions.`
          },
          {
            title:`Deed of conveyance`,
            text:`This is to be finally, executed and stamp duty to be paid.`
          },
          {
            title:`Registration of the deed `,
            text:`The conveyance should be duly registered with the registrar of the sub registrar office with all the stamp duties paid for legal effect.`
          },
          {
            title:`The names of the new owners`,
            text:`The names of the new owners should come on the 7/12 extracts within six months of the registration.`
          }
        ]
        }
      ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "25-points-to-check-before-buying-agricultural-land-in-maharashtra",
    },
    {
        id:10,
      image: "	https://www.incubeindia.com/incubelandmarks/images/group-farming.webp",
      image2:"",
      image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Farming made easy",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`India is a big family. It has been a family of different cultures, different languages, different cuisines. All such people, one big family. The Indian family eats Idlis and Puran polis with the same love as Khakras and butter chicken. All these foods make for some of the tastiest dishes in the world. The khau-galli of every city has unique tastes. But this family of different khau-gallis has one thing in-common – the sumptuous food they have to offer! The Indian family has a fertile pan, waiting to cook amazing foods. A fertile heart, waiting to love a billion people. A fertile mind, waiting to explore all cultures.`
        },
        {
          title:``,
          text:`A major part of our cultures has been to be a healthy family. In today’s fast food age, ‘Organic’ has become a movement! To give children the purest milk, the cleanest vegetables, the freshest fruits! The best way to do this is by doing what India did – different people came together to create something great! This family of Indians has turned to Group farming. They come together not because they are related by blood, but because they share the same purpose. Group farms are schemes involving multiple individuals who share resources for the purpose of farming. They share the cost and use of machines so that instead of 4 people spending on 4 machines, they spend on just one!`,
        },
        {
          title:``,
          text:`Group farming builds the community, makes them stronger and allows them to do things much better. They get discounts because they buy together. They get higher income because they sell together! They form one big family, to get the freshest food, together, like India. Group farming has sizable returns, for sure. To have amazing returns you obviously need smart investments. Smart investments in the right kind of ploughing equipment, irrigation and fertilizer. But the SMARTEST investment you would do is the land! Buying the best land is the main way to make Group Farming a success.`,
        },
        {
          title:``,
          text:`You must look for the land, out of so many schemes that are available. Every piece of land will have different soil quality, climate and the challenges that come along with it. It’s like building a great country. You need to look at the past, make decisions for the present and prepare for the future! It’s not easy. There can be tough times, but such times can be made easier with correct experts. Experts, more than anyone, give us a good sense of direction. They can help us find the best way to success. So why not get some direction from an expert and then step on the soil you want to make yours?`,
        }   
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "article-group-farming",
    },
    {
        id:11,
      image:
        "https://www.incubeindia.com/incubelandmarks/images/Myths_of_land_invesmtent.webp",
        image2:"",
        image3:"",
      postedOn: "JUNE 30, 2021",
      category: "LAND DEVELOPMENT",
      heading: "Land investment - Simplifying the Myths",
      data:[
        {
        contentTitle:``,
        content:[
        {
          title:``,
          text:`You must have often listened to statements like “Invest in land, only when you have lot of disposable income”, or “Only the rich can invest in land”. But what if we told you, that all these statements are completely false, and you can invest in land as well? Wouldn’t it be a moment of pride to create your own land bank? Let us make it simple for you by talking about some myths, when it comes to investment, especially in land.`
        },
        {
          title:`Myth no. 1: Land is a costly affair`,
          text:`It is definitely true that land is costlier than any other normal investments like fixed deposits, mutual funds or gold. But this has a twist to it. Let us take a simple example. Imagine that you have to buy your dream car, probably a BMW. Is it possible for you to go out and buy it tomorrow? No, right? You will probably consult your financial advisor, check your balances, think of some future expenses, evaluate all options and ask few of your friends; before actually buying the car.

The land that you can own, is similar to your dream car. It is not possible to buy it without having enough knowledge about it. Here, your land developer, or land investment consultant comes into picture. As we always say, never invest in anything, without knowing it thoroughly.

So yes, land is costly, but so is your dream car. With proper planning of investments and balances, you can start to create your own land bank.`,
        },
        {
          title:`Myth no. 2: Too many trips of government offices!`,
          text:`This is one of the most common myths that one comes across. That there is too much red tape, when it comes to buying/investing in land. On the contrary, with the new rules & guidelines in place, buying land is as easy as investing in a mutual fund. If your papers are in order, and in accordance with the guidelines, buying your first piece of land is a cake walk. Make sure you have someone knowledgeable with you, who knows the ins and outs of land investment. The best way to learn, is often from another’s experience. Remember, it is not your job to buy land, it is your job to just commit the investment. A good consultant is someone who keeps his client well informed and takes care of all the necessary paper work needed to create your land bank. To be on a safer side, make sure your land consultant has had an experience of dealing in varied types of land in varied geographical areas.`,
        },
        {
          title:`Myth no. 3: Land is a dead investment.`,
          text:`Contrary to the popular opinion, land is an investment which will keep giving you for life. Unlike certain other investments, land is limited. It cannot be increased in any possible way. Therefore, it naturally has higher demand. Something, which is scarce, is always wanted by many.

Make sure you stay away from this myth. The value of the land you buy always, mark our words, always appreciates. Buying land is not hunting, it is more like farming. It takes patience, persistence and decisive thinking. As it is often said, “Great things take their own time”. Investment in land is not for quick winners, it is for those with a long term vision. You will immediately get food, once you hunt, but the taste of self-grown crop beats the best hunt!`,
        }   ,
        {
          title:`Myth no. 4: Why buy land, when I don’t want a second home?`,
          text:`Before making any investment, we always ascertain the purpose of our investment first. For example, we take out a policy for our child’s education, another policy to make our retirement better, and another to ensure our goods/property. Though the primary form of investment is insurance, the purposes are many. It is the same case, when it comes to land. It can be bought for sale after appreciation, building a second home, cultivating on the land, or even for a commercial purpose like a resort or a hotel. You need to ascertain your purpose first. Once your purpose to invest in land is clear, everything from selection to utility to sale, gets in order. We hope we have demystified land investments a bit for you today. Patience is the key in every investment you make. A notable investor from the US once said, “Never wait to invest in land, rather, invest in land and then wait.`,
        }   
      ]
      }
    ],
      authorName: "ANIRUDDHA JOSHI",
      authorImage: "https://www.themezaa.com/html/pofo/images/avtar-01.jpg",
      linkToFullArticle: "myths-of-land-investments",
    }
  ];
  const { articleRoute } = useParams();
  const navigate = useNavigate();
  const [currentArticle, setCurrentArticle] = useState({});
  useEffect(() => {
    setTimeout(()=>{
      setShowContent(true);
    },1000);
    setCurrentArticle(articlesData.filter((ele)=> ele.linkToFullArticle===articleRoute)[0]);
    
    window.scroll(0,0);
  }, [articleRoute]);
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      {showContent===false && <LoadingScreen />}
      {
      showContent===true &&  
      <>
        <ArticlesNavbar />

        <div className="margin-top-15vh col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
          <div className="pb-5 bg-theme-light-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-10 row px-0 display-flex">
              <div className="mt-5 fs-medium-css col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-0 display-flex-start">
                {currentArticle.heading.toUpperCase()}
              </div>

              <div className="mt-5 fs-medium-small-css color-theme-dark-grey font-roboto col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-0 display-flex-end">
                {currentArticle.postedOn}
                <i className="fs-smallest-css color-theme-medium-grey mx-2 w-auto px-0 fa-solid fa-circle"></i>
                BY {currentArticle.authorName}
                <i className="fs-smallest-css color-theme-medium-grey mx-2 w-auto px-0 fa-solid fa-circle"></i>
                {currentArticle.category}
              </div>
            </div>
          </div>

          <div className="bg-white py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <div className="mb-5 col-12 col-sm-12 col-md-12 col-lg-11 col-xl-10 row px-0 display-flex-align-items-top">
              <div className="mt-0 col-11 col-sm-11 col-md-11 col-lg-8 col-xl-8 row px-0 display-flex">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                    <img
                      src={currentArticle.image}
                      className="w-100 px-0 "
                      alt={currentArticle.heading}
                    />
                  </div>

                  {currentArticle.data.map((ele,index)=> 
                  <div 
                  key={index} 
                  className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex"
                  >
                    {ele.contentTitle!==`` 
                    && 
                    <div className="mt-5 fs-large-css color-theme-red fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                        {ele.contentTitle}
                    </div>}
                  {ele.content.map((para, index)=> 
                  <div key={index} className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                    {para.title!==`` && <div className="mt-4  fs-medium-css color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                      {para.title !==`` && para.title.toUpperCase()}
                  </div>}

                  <div className="mt-2 font-roboto fs-slight-small-css color-theme-dark-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                    {para.text}
                  </div>

                 {para.keyPoints && <div className="mt-0 font-roboto fs-slight-small-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                      {para.keyPoints.map((ele,index)=> 
                      <div key={index} className="mt-2 mx-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                        <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex-start mx-0 color-theme-dark-grey font-roboto fs-slight-small-css ">
                          {index+1} {". "}{ele}
                        </div>
                        </div>)}
                  </div>}
                  </div>)}
                  </div>
                )}

                  <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                    <div className="border-left-red col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12   row px-5 display-flex color-theme-dark-grey font-roboto fw-light fs-medium-css">
                      We are committed towards trust, transparency and financial
                      prudence. Our core concept lies in experimenting new
                      things and passing the best to our clients. We cater to
                      services such as land development, land acquisitions, land
                      liasoning and joint ventures. <br />- ANIRUDDHA JOSHI
                    </div>
                  </div>

                  <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-slight-large-css ">
                   DISCLAIMER:
                  </div>

                  <div className="mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start text-align-justify fs-medium-small-css font-roboto color-theme-dark-grey">
                  The information provided on the Incube Landmarks Private Limited blog is for general informational purposes only. We strive to ensure that the information on this blog is accurate and up-to-date, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information contained here.

The use of any information on this blog is at your own risk. We shall not be liable for any losses, injuries, or damages from the use of this information. <br />

This blog may contain links to external websites that are not maintained or controlled by Incube Landmarks Private Limited. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a ecommendation or endorsement of the views expressed within them.

The views and opinions expressed in this blog are those of the authors and do not necessarily reflect the official policy or position of Incube Landmarks Private Limited. <br />

All information on this blog is provided in good faith. It's essential to consult with a professional or appropriate authority for advice related to any specific matter.
                  </div>
                </div>
              </div>

              <div className={`${window.innerWidth>765 ? "px-5" : "px-0"} mt-5 col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 row display-flex-align-items-top`}>
                    <div className="pb-2 border-bottom-dark-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-css">
                        OTHER ARTICLES
                    </div>

                    {articlesData.map((ele,index)=> 
                    <>
                    {ele.heading!==currentArticle.heading && 
                     <div key={index}
                     onClick={()=>navigate(`/articles/${ele.linkToFullArticle}`)}
                     className="cursor-pointer mt-4 col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                        <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row pe-3 ps-0 display-flex">
                            <img src={ele.image} className="w-100 other-articles-image px-0" alt={ele.heading} />
                        </div>
                        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 ps-0 row pe-4 display-flex">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-medium-small-css">
                                {ele.heading}
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-medium-small-css font-roboto color-theme-dark-grey">
                                {ele.postedOn}
                            </div>
                        </div>
                    </div>}</>)}
              </div>
            </div>
          </div>
        </div>
        <SubFooter />

        <Footer />
      </>
      }
    </div>
  );
}
