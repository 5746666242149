import React from 'react'
import HomePage from './HomePage'
import { Navigate, Route, Routes } from 'react-router-dom'
import AboutUsPage from './AboutUsPage'
import ServicesPage from './ServicesPage'
import ContactUsPage from './ContactUsPage'
import ProjectsPage from './ProjectsPage'
import AvniPage from './AvniPage'
import Faqs from './Faqs'
import TestimonialsPage from './TestimonialsPage'
import ArticlesPage from './ArticlesPage'
import Article from './Article';
import Customized from './Customized';
import FarmerCertificate from './FarmerCertificate'
import Privacy from './Privacy'
import NotFound from './NotFound'
import Sitemap from './Sitemap'
import ShikrapurPage from './ShikrapurPage'
import TanaliPage from './TanaliPage'
import MulshiPage from './MulshiPage'
import Enquiry from './Enquiry'
import MangaonPage from './MangaonPage'
import LiveChat from './LiveChat'
// https://www.themezaa.com/html/pofo/single-project-page-07.html
// https://www.themezaa.com/html/pofo/home-classic-corporate.html

export default function UserApp() {
  
  return (
    <div className='position-relative mx-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
       
        <Routes>
            <Route exact path="/" element= {<HomePage />} />
            <Route exact path="/aboutus" element= {<AboutUsPage />} />
            <Route exact path="/services" element= {<ServicesPage />} />
            <Route exact path="/customized" element= {<Customized />} />
            <Route exact path="/projects" element= {<ProjectsPage />} />
            <Route exact path="/contactus" element= {<ContactUsPage />} />
            <Route exact path="/articles" element= {<ArticlesPage />} />
            <Route exact path="/articles/:articleRoute" element= {<Article />} />
            <Route exact path="/farmercertificate" element= {<FarmerCertificate />} />
            
            <Route exact path="/avni" element= {<AvniPage />} />
            <Route exact path="/shikrapur" element= {<ShikrapurPage />} />
            <Route exact path="/tanali" element= {<TanaliPage />} />
            <Route exact path="/mulshi" element= {<MulshiPage />} />
            <Route exact path="/mangaon" element= {<MangaonPage />} />
            <Route exact path="/faqs" element= {<Faqs />} />
            <Route exact path="/testimonials" element= {<TestimonialsPage />} />
            <Route exact path="/sitemap" element= {<Sitemap />} />
            <Route exact path="/privacy" element= {<Privacy />} />
            <Route exact path="/404" element= {<NotFound />} />
            <Route exact path="/*" element= {<NotFound />} />
            {/* <Route exact path="/*" element= {<Navigate to={"/"} />} />  Redirect to homepage directly */}

        </Routes>
        <LiveChat />

    </div>
  )
}

