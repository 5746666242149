import React, { lazy, Suspense, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';

const Navbar= lazy(()=>import("./Navbar"));
const Footer= lazy(()=>import("./Footer"));
const NotFoundContent= lazy(()=>import("./NotFoundContent"));


export default function NotFound() {
    useEffect(()=>{
        window.scroll(0,0);
        },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />}>
            <Navbar />
            <NotFoundContent />
            <Footer />
        </Suspense>
    </div>
  )
}
