import React, { lazy, Suspense, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';

const Navbar= lazy(()=>import("./Navbar"));
const Header= lazy(()=>import("./Header"));
const PrivacyContent= lazy(()=>import("./PrivacyContent"));
const Footer= lazy(()=>import("./Footer"));

export default function Privacy() {
    useEffect(()=>{
        window.scroll(0,0);
    });
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />}>
            <Navbar />
            <Header title={"PRIVACY POLICY"} tagline={"Terms & Conditions"} />
            <PrivacyContent />
            <Footer />
        </Suspense>
    </div>
  )
}
