import React, { lazy, Suspense } from 'react';
import LoadingScreen from './LoadingScreen';

const Navbar= lazy(()=>import("./Navbar"));
const Header= lazy(()=>import("./Header"));
const SitemapContent= lazy(()=>import("./SitemapContent"));
const Footer= lazy(()=>import("./Footer"));

export default function Sitemap() {
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />}>
            <Navbar />
            <Header title={"SITEMAP"} tagline={"Navigation"} />
            <SitemapContent />
            <Footer />
        </Suspense>
    </div>
  )
}
