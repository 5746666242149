import React, { lazy, Suspense } from 'react';
import LoadingScreen from './LoadingScreen';

const Header = lazy(()=>import("./Header"));
const Navbar = lazy(()=>import("./Navbar"));
const CustomizedContent = lazy(()=>import("./CustomizedContent"));
const Footer = lazy(()=>import("./Footer"));

export default function Customized() {
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />}>
            <Navbar />
            <Header title="CUSTOMIZED" tagline="" />
            <CustomizedContent />
            <Footer />
        </Suspense>
    </div>
  )
}
