import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import LoadingScreen from "./LoadingScreen";

export default function Enquiry(props) {
    const [showForm, setShowForm] = useState(false); 
    const [formData, setFormData] = useState({
        name:"",
        phone:"",
        email:"",
        message:"",
        originOfEnquiry:""
    });
    const [validName, setValidName] = useState(null);
    const [validPhone, setValidPhone] = useState(null);
    const [validEmail, setValidEmail] = useState(null);
    const [validMessage, setValidMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [showOtpForm , setShowOtpForm] = useState(false);

    const [userOtp , setUserOtp] = useState(0);

    useEffect(()=>{
        setFormData({...formData, originOfEnquiry: props.originOfEnquiry});
    },[]);

    function validateInputPattern(event, regex,  setValidationResult)
    {
        if(event.target.value!=="")
        {
          if(regex.test(event.target.value)===true)
          {
            setValidationResult(true);
            setFormData({...formData ,  [event.target.name] :event.target.value});
          }
          else if(regex.test(event.target.value)===false)
          {
            setValidationResult(false);
          }
        }
        else
        {
          setValidationResult(null);
        }
    }

    function generateOtp()
    {
      return String(Math.ceil(100000 + Math.random()*900000));
    }

    async function handleEmailOtp()
    {
      try {
        setLoading(true);
        if (localStorage.getItem("Data")) {
          localStorage.removeItem("Data");
        }
        const otp = generateOtp();
        const cipherText = CryptoJS.AES.encrypt(otp, process.env.REACT_APP_ENCRYPTION_KEY).toString();
        localStorage.setItem("Data", JSON.stringify(cipherText));
  
        await fetch("https://incubelandmarks.com/api/sendOtp.php", {
          method: "post",
          body : JSON.stringify({
            mobile : "91" + formData.phone , 
            otp : otp
          }),
          headers : {
            "Content-Type" : "application/json"
          }
        })
        .then(async(res)=>{
          res = await res.json();
          if(res.status===true)
          {
            alert("OTP sent on your whatsapp number successfully!");
            window.scrollBy({
              top: 300,
              behavior: "smooth",
            });
            setShowOtpForm(true);
          }
          else
          {
            alert("Error while sending OTP!");
          }
          setLoading(false);
        })
        .catch((err)=>{
          alert("Error while communicating with server!");
          setLoading(false);
        })
      } catch (error) {
        console.log(error);
        alert("Error while sending request to server!");
        setLoading(false);
      }
    }

    async function handleValidateOtp()
    {
      setLoading(true);
      try {
        if(localStorage.getItem("Data"))
        {
          const bytes = CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem("Data")), process.env.REACT_APP_ENCRYPTION_KEY);
          const originalText = bytes.toString(CryptoJS.enc.Utf8);
          if(originalText===userOtp)
          {

            await fetch("https://incubelandmarks.com/api/sendProjectEnquiry.php" , {
              method:"post",
              body:JSON.stringify(formData),
              headers:{
                  "Content-Type" : "application/json"
              }
            })
            .then(async (res)=>{
              res = await res.json();
              if(res.success)
              {
                alert("Enquiry Submitted Successfully!");
                setLoading(false);
              }
              else
              {
                alert(res.error);
                setLoading(false);
              }
            })
            .catch(err=> {
              console.log(err);
              alert("Error while submitting Enquiry, please check your internet connection.")});
              setLoading(false);
          }
          else
          {
            alert("Invalid OTP!");
            setLoading(false);
          }
          localStorage.removeItem("Data");
      }
      else
      {
        alert("Something went wrong!");
        setLoading(false);
      }
      window.location.reload();
      } catch (error) {
        console.log(error);
          alert("Error while sending request!");
          setLoading(false);
          window.location.reload();
      }
    }
  return (
    <div className={`display-flex-align-items-top w-auto enquiry-container ${showForm===true  ? "show" : ""} mx-0 px-0`}>
        
        <div className="enquiry-form-container px-5 pt-2 pb-5 row bg-theme-dark-blue display-flex-align-items-top mx-0">
        <div className="mx-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-end">
        {showForm===true && <button
          onClick={()=>{
              if(showForm===true)
              {
                  setShowForm(false);
              }
              else
              {
                  setShowForm(true);
              }
          }}
          className='btn-universal-red-converse py-1 px-2 w-auto'>
              <i className="w-auto px-0 fa-solid fa-xmark"></i>
          </button>}
            </div>
            <div className="mx-0 mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                onChange={(event)=>validateInputPattern(event, /^[a-zA-Z\s]+$/, setValidName)}
                type="text" name="name" placeholder='Enter Name*' className={`${validName===true && "" } ${validName===false && "wrong-input"} ${window.innerWidth<450 ? "px-1" : ""} w-100 fs-slight-small-css input-field-blue`} />
            </div>

            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                max={10}
                maxLength={10}
                onChange={(event)=>validateInputPattern(event, /^\d{10}$/, setValidPhone)} type="tel" name="phone" placeholder='Enter Whatsapp Number*' className={`${validPhone===true && "" } ${validPhone===false && "wrong-input"} ${window.innerWidth<450 ? "px-1" : ""} w-100 fs-slight-small-css input-field-blue`} />
            </div>

            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                onChange={(event)=>{
                    if(event.target.value!=="")
                      {
                        if(
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+(\.[a-z]+)+$/.test(event.target.value)===true 
                        ||
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+(\.[a-z]+)+\.[a-z]+$/.test(event.target.value)===true
                      ||
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+(\.[a-z]+)+(\.[a-z]+)+\.[a-z]+$/.test(event.target.value)===true
                      ||
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.[a-z]+$/.test(event.target.value)===true
                    )
                        {
                          setValidEmail(true);
                          setFormData({...formData ,  email :event.target.value});
                        }
                        else if(
                          /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+(\.[a-z]+)+$/.test(event.target.value)===false 
                            &&
                          /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+(\.[a-z]+)+\.[a-z]+$/.test(event.target.value)===false
                          &&
                          /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z]+(\.[a-z]+)+(\.[a-z]+)+\.[a-z]+$/.test(event.target.value)===false
                          &&
                          /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.[a-z]+$/.test(event.target.value)===false
                        )
                        {
                          setValidEmail(false);
                        }
                      }
                      else
                      {
                        setValidEmail(null);
                      }
                  }}   type="email" name="email" placeholder='Enter Email*' className={`${validEmail===true && "" } ${validEmail===false && "wrong-input"} ${window.innerWidth<450 ? "px-1" : ""} w-100 fs-slight-small-css input-field-blue`} />
            </div>

            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                max={60}
                maxLength={60}
                onChange={(event)=>validateInputPattern(event,/^[a-zA-Z0-9\s,.-]+$/, setValidMessage)}  type="text" name="message" placeholder='Enter Message*' className={`${validMessage===true && "" } ${validMessage===false && "wrong-input"} ${window.innerWidth<450 ? "px-1" : ""} w-100 fs-slight-small-css input-field-blue`} />
            </div>

            {showOtpForm!==true && <div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <button
            disabled = {
                validName===true && 
                validEmail===true &&
                validPhone===true && 
                validMessage===true &&
                formData.originOfEnquiry!=="" ?
                false : true
            }
            onClick={handleEmailOtp}
            className='btn-universal-converse w-auto '>
                {loading===false ? "SEND OTP" : "Sending Otp..."}
             </button>
            </div>}

            {showOtpForm===true && <><div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex text-white">
                OTP Sent On whatsapp!
            </div>

            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <input
                onChange={(event)=>setUserOtp(event.target.value)}  type="text" name="message" placeholder='Enter OTP*' className={` w-100 fs-slight-small-css input-field-blue`} />
            </div>

            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <button
            onClick={handleValidateOtp}
            className='btn-universal-converse w-auto '>
                {loading===false ? "SUBMIT" : "Submitting..."}
             </button>
            </div></>}
        </div>
        {showForm===false && <div className="px-0 mx-0 position-relative rotate-90">
          <button
          onClick={()=>{
              if(showForm===true)
              {
                  setShowForm(false);
              }
              else
              {
                  setShowForm(true);
              }
          }}
          className='btn-universal-red display-flex'>
               ENQUIRE NOW
          </button>
        </div>}
        
    </div>
  )
}