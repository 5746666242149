import React, { lazy, Suspense, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';
const AvniPageContent = lazy(()=>import('./AvniPageContent'));
const ProjectsNavbar = lazy(()=>import('./ProjectsNavbar'));
const Footer = lazy(()=>import('./Footer'));
const SubFooter = lazy(()=>import("./SubFooter"));
const Enquiry = lazy(()=>import("./Enquiry"));

export default function AvniPage() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />}>
          <ProjectsNavbar buttonOptions={[
            {label:"Know US" , hrefLink:"#knowus"},
            {label:"Why Us" , hrefLink:"#whyus"},
            {label:"Solutions" , hrefLink:"#solutions"},
            {label:"Amenities" , hrefLink:"#amenities"},
            {label:"Gallery" , hrefLink:"#gallery"},
            {label:"PROJECTS" , hrefLink:"/projects"}
          ]} />
            <AvniPageContent />
            <SubFooter />
            <Footer />
            <Enquiry originOfEnquiry = "AVNI - CHIPLUN" />
        </Suspense>
    </div>
  )
}
