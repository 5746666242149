import React, { lazy, Suspense, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';

const Header = lazy(()=>import("./Header"));
const Navbar = lazy(()=>import("./Navbar"));
const FaqsContent = lazy(()=>import("./FaqsContent"));
const Footer = lazy(()=>import("./Footer"));
const SubFooter = lazy(()=>import("./SubFooter"));

export default function Faqs() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />} >
            <Navbar />  
            <Header title="FAQS" tagline={"Frequently Asked Questions"} />
            <FaqsContent />
            <SubFooter />
            <Footer />
        </Suspense>
    </div>
  )
}
