import React, { lazy, Suspense, useEffect } from 'react';
import LoadingScreen from './LoadingScreen';

const MulshiPageContent = lazy(()=>import('./MulshiPageContent'));
const ProjectsNavbar = lazy(()=>import('./ProjectsNavbar'));
const Footer = lazy(()=>import('./Footer'));
const SubFooter = lazy(()=>import("./SubFooter"));
const Enquiry = lazy(()=>import("./Enquiry"));


export default function MulshiPage() {
    useEffect(()=>{
        window.scroll(0,0);
      },[]);
      return (
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
            <Suspense fallback={<LoadingScreen />}>
              <ProjectsNavbar buttonOptions={[
                {label:"Know US" , hrefLink:"#knowus"},
                {label:"We Offer" , hrefLink:"#weoffer"},
                {label:"Why US" , hrefLink:"#whyus"},
                {label:"Amenities" , hrefLink:"#amenities"},
                {label:"Gallery" , hrefLink:"#gallery"},
                {label:"Projects" , hrefLink:"/projects"}
              ]} />
                <MulshiPageContent />
                <SubFooter />
                <Footer />
                <Enquiry originOfEnquiry = "MULSHI - VISHODHAN" />
            </Suspense>
        </div>
  )
}
