import React, { useState } from 'react';
import ChatBot from "react-simple-chatbot";
import { ThemeProvider , createGlobalStyle  } from 'styled-components';

export default function LiveChat(props) {

    const GlobalStyle = createGlobalStyle`
  .rsc-bubble, .rsc-bubble-text {
    font-size: 5px; /* Adjust the font size here */
  }

    .rsc-bubble {
    border-radius: 0px !important; /* Remove border-radius for bot bubbles */
  }

  .rsc-user {
    border-radius: 0px !important; /* Remove border-radius for user bubbles */
  }
`;

  const [chatbotStatus , setChatbotStatus] = useState("close");
  
  const steps =[
    {
      id:'1',
      message:"Welcome to Incube Landmarks Pvt. Ltd Community",
      trigger: '2'
    },
    {
      id:'2',
      message: "What's your name?",
      trigger: '3'
    },
    {
      id: '3',
      user : true,
      trigger : '4'
    },
    {
      id:'4',
      message: "What's your phone number?",
      trigger: '5'
    },
    {
      id: '5',
      user : true,
      trigger : '6'
    },
    {
      id:'6',
      message: "What's your email address?",
      trigger: '7'
    },
    {
      id: '7',
      user : true,
      trigger : '8'
    },
    {
        id:'8',
        message: "What's your message for us?",
        trigger: '9'
      },
      {
        id: '9',
        user : true,
        trigger : "finish"
      },
      {
        id:'finish',
        message: "Thank You for showing your interest, we'll get back to you as soon as possible!!!",
        end: true
      }
  ];

  const theme = {
    background: '#ededed',
    headerBgColor: '#011b2d',
    headerFontColor: '#ededed',
    headerFontSize: '15px',
    botBubbleColor: '#03365a',
    botFontColor: '#ededed',
    userBubbleColor: '#011b2d',
    userFontColor: '#ededed',
  };
  async function handleEnd({ steps, values }){
    try {
      
      await fetch("https://incubelandmarks.com/api/sendChatEnquiry.php",{
        method:"post",
        body:JSON.stringify({
          name: values[0],
          phone: values[1],
          email: values[2],
          message: values[3]
        })
      })
      .then(async(res)=>{
        res = await res.json();
        if(res.success)
        {

        }
        else
        {
        }
        window.location.reload();
      })
      .catch();


    } catch (error) {
        alert("Something went wrong!");
        window.location.reload();
    }
  };

  return (
    <div id='chatbot-container' className=' chatbot-screen pt-4 w-auto row px-0 display-flex-align-items-top'>
          {chatbotStatus==="close" && <div className=" chatbot-toggle-btn col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex-end py-0">
            <button 
            onClick={()=>{
                document.body.style.scroll="scroll";
                document.getElementById('chatbot-container').classList.toggle("active");
                if(chatbotStatus==="open")
                  {
                    setChatbotStatus("close");
                  }
                  else
                  {
                    setChatbotStatus("open");
                  }
                
            }} 
            className='btn-universal-red  close-live-chat w-auto'>
            { 
            chatbotStatus==="open" ? 
            <i className="fa-solid fa-xmark"></i> : 
            "Live Chat"
            }
            </button>
          </div>}
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex position-relative">

          {chatbotStatus==="open" && <div className=" chatbot-close-btn col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex-end py-2">
            <button 
            onClick={()=>{
                document.body.style.scroll="scroll";
                document.getElementById('chatbot-container').classList.toggle("active");
                if(chatbotStatus==="open")
                  {
                    setChatbotStatus("close");
                  }
                  else
                  {
                    setChatbotStatus("open");
                  }
                
            }} 
            className='btn-universal-red-converse  close-live-chat w-auto'>
            { 
            <i className="fa-solid fa-xmark"></i> 
            }
            </button>
          </div>}

            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ChatBot
              headerTitle = {"HELLO!"}
              handleEnd={handleEnd}
              botAvatar = {"https://incubeindia.com/incubelandmarks/images/male.webp"}
              className="mt-0 pt-0 custom-chatbot ps-0 pe-0 display-flex"
              steps = {steps} />
            </ThemeProvider>
          </div>
    </div>
  )
}
