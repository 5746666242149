import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import UserApp from './components/UserApp';
import NotFound from './components/NotFound';

function App() {
  return (
    <div className="container-fluid row px-0 m-0 display-flex">
      <BrowserRouter>
        <Routes>
          <Route exact path='/*' element={<UserApp />} />
          
        </Routes>
      </BrowserRouter>  
    </div>
  );
}

export default App;
