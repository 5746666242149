import React, { lazy, Suspense, useEffect } from 'react'
import LoadingScreen from './LoadingScreen';

const Navbar = lazy(()=>import("./Navbar"));
const LandingPage = lazy(()=>import("./LandingPage"));
const WelcomePage = lazy(()=>import("./WelcomePage"));
const Values = lazy(()=>import("./Values"));
const Projects = lazy(()=>import("./Projects"));
const ProcessOfBuying = lazy(()=>import("./ProcessOfBuying"));
const WhyChooseUs = lazy(()=>import("./WhyChooseUs"));
const Counters = lazy(()=>import("./Counters"));
const TestimonialsHomepage = lazy(()=>import("./TestimonialsHomepage"));
const Form = lazy(()=>import("./Form"));
const Blogs = lazy(()=>import("./Blogs"));
const Footer = lazy(()=>import("./Footer"));

export default function HomePage() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />} >
          <Navbar />
          <LandingPage />
          <WelcomePage />
          <Values />
          <Projects />
          <ProcessOfBuying />
          <WhyChooseUs />
          <Counters />
          <TestimonialsHomepage />
          <Form />
          <Blogs />
          <Footer />
        </Suspense>
    </div>
  )
}
